export default {
  WETH: 18,
  WBTC: 8,
  HUNT: 18,
  WRN: 18,
  ETH: 18,
  WBNB: 18,
  BNB: 18,
  BTCB: 8
};
