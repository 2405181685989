import { ChainId } from "@uniswap/sdk";
import { CHAIN_ID_BSC_MAIN, CHAIN_ID_BSC_TESTNET } from "utils/chainId";

export default {
  [CHAIN_ID_BSC_TESTNET]: {
    WRNRewardPool: "0xB84Accc7376171AA6e9a03Bf1Cc9eb76A889Ce1B",
    WRN: "0xDD42F573125b920b25769B903D4d2C831BD46340",
    WBNB: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F",
    HUNT: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F",
    ETH: "0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378",
    WETH: "0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378",
    BTCB: "0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8",
    WBTC: "0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8",
    UNISWAP_ROUTER: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  },
};
