import React from "react";

function DiscordFAB(props) {
  return (
    <a
      target="_blank"
      href="https://discord.gg/8zpebQrr4K"
      rel="noopener noreferrer"
    >
      <div className="discord-fab centered link">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="20"
          viewBox="0 0 27 20"
        >
          <path
            d="M 17.811 0.001 L 17.476 0.395 C 21.552 1.679 23.445 3.531 23.445 3.531 C 21.223 2.267 18.769 1.461 16.23 1.16 C 14.619 0.978 12.993 0.995 11.387 1.21 C 11.244 1.21 11.123 1.235 10.979 1.26 C 10.14 1.334 8.102 1.654 5.538 2.816 C 4.651 3.235 4.123 3.531 4.123 3.531 C 4.123 3.531 6.113 1.58 10.428 0.297 L 10.188 0 L 10.188 0 L 10.11 0 C 9.589 0.009 6.59 0.169 3.452 2.593 C 3.452 2.593 0 9.037 0 16.987 C 0 16.987 2.014 20.568 7.311 20.741 C 7.311 20.741 7.879 20.029 8.475 19.265 C 8.549 19.169 8.623 19.073 8.698 18.977 L 8.917 18.692 C 5.874 17.753 4.723 15.777 4.723 15.777 C 4.723 15.777 4.962 15.95 5.394 16.199 C 5.417 16.222 5.441 16.247 5.49 16.271 C 5.562 16.321 5.633 16.346 5.706 16.395 C 6.268 16.716 6.853 16.996 7.455 17.235 C 8.594 17.698 9.774 18.053 10.979 18.296 C 12.777 18.643 14.887 18.765 17.188 18.321 C 18.385 18.106 19.551 17.749 20.664 17.259 C 21.636 16.881 22.561 16.392 23.421 15.802 C 23.421 15.802 22.223 17.828 19.083 18.74 L 19.301 19.024 L 19.523 19.31 C 20.112 20.061 20.664 20.741 20.664 20.741 C 25.962 20.568 28 16.988 28 16.988 C 28 9.037 24.549 2.594 24.549 2.594 C 21.41 0.169 18.409 0.009 17.891 0.001 L 17.811 0.001 M 9.517 9.21 C 9.517 9.21 9.517 9.21 9.517 9.21 C 8.834 9.21 8.223 9.518 7.782 10.015 C 7.341 10.512 7.072 11.197 7.072 11.951 C 7.072 13.457 8.175 14.692 9.517 14.692 C 10.884 14.692 11.962 13.458 11.962 11.951 C 11.986 10.444 10.884 9.21 9.517 9.21 M 15.822 11.951 C 15.822 13.457 16.924 14.692 18.267 14.692 C 19.633 14.692 20.712 13.458 20.712 11.951 C 20.712 10.444 19.633 9.21 18.267 9.21 C 18.267 9.21 18.267 9.21 18.267 9.21 C 16.901 9.21 15.822 10.444 15.822 11.951 M 20.384 10 C 20.384 10 20.384 10 20.384 10 C 20.384 10 20.384 10 20.384 10 C 20.384 10 20.384 10 20.384 10 C 20.384 10 20.384 10 20.384 10 L 20.384 10"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>

        <p className="white">
          Let's chat on
          <br />
          Discord
        </p>
      </div>
    </a>
  );
}

export default DiscordFAB;
