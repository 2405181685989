export const SECONDS_IN_MONTH = 2592000;

export const TX_TYPE_LOCKUP = "TX_TYPE_LOCKUP";
export const TX_TYPE_APPROVE = "TX_TYPE_APPROVE";
export const TX_TYPE_EXIT = "TX_TYPE_EXIT";
export const TX_TYPE_CLAIM_BOTH = "TX_TYPE_CLAIM_BOTH";
export const TX_TYPE_CLAIM_BONUS = "TX_TYPE_CLAIM_BONUS";
export const TX_TYPE_CLAIM_REWARD = "TX_TYPE_CLAIM_REWARD";
export const TX_TYPE_UNISWAP_APPROVE = "TX_TYPE_UNISWAP_APPROVE";
export const TX_TYPE_UNISWAP_WRAP = "TX_TYPE_UNISWAP_WRAP";
export const TX_TYPE_UNISWAP_SWAP = "TX_TYPE_UNISWAP_SWAP";
